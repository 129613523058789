import * as React from "react";
import { Helmet } from "react-helmet";
import Resume from "../components/Resume";
import StickyHeader from "../components/StickyHeader";

import styled from "styled-components";
import "../index.css";

const StyledMain = styled.main`
  background-color: #fbfbfb;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  // add this for sticky-header space
  padding-top: 2rem;
  // add this for sticky-footer space
  padding-bottom: 0rem;
`;

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Raleway:300,700"
          rel="stylesheet"
        />
      </Helmet>
      <StyledMain>
        <StickyHeader />
        <Resume />
      </StyledMain>
    </>
  );
};

export default IndexPage;

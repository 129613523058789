import React, { Component } from "react";
import { inRange } from "lodash";
import githubIcon from "./icons/github.svg";
import instagramIcon from "./icons/instagram.svg";
import linkedinIcon from "./icons/linkedin.svg";
import twitterIcon from "./icons/twitter.svg";
import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
0% {
    opacity: 0;
}

100% {
    opacity: 0.8;
}
`;

const entryRightAnimation = keyframes`
  0%,
  50% {
    opacity: 0;
    transform: translate(-100px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
`;

const entryLeftAnimation = keyframes`
  0%,
  50% {
    opacity: 0;
    transform: translate(+100px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
`;

const StyledStickyHeader = styled.div`
  z-index: 666;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3rem;
  width: 100%;
  background-color: #fbfbfb;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row-reverse;
  min-width: 320px;
  opacity: 0.8;
  transition: opacity 0.5s ease-in-out;
  /* animation: 1s fadeIn; */
  animation-name: ${fadeInAnimation};
  animation-duration: 1s;

  &:hover {
    opacity: 1;
  }

  .full-name {
    position: absolute;
    left: 0;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    /* animation: entryRight 1s ease-in; */
    animation-name: ${entryRightAnimation};
    animation-duration: 1s;
  }

  .head-character {
    padding-left: 10px;
  }

  .padding-left-20 {
    padding-left: 20px;
  }

  .tail-characters {
    width: auto;
    overflow: hidden;
    transition: max-width 0.5s ease-in-out;
  }

  .icons {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    /* animation: entryLeft 1s ease-in; */
    animation-name: ${entryLeftAnimation};
    animation-duration: 1s;
    padding: 10px;
    a {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
    .icon-img {
      height: 1.5rem;
      width: 2rem;
      transition: all 0.1s ease-in-out;
    }
    .icon-img:hover {
      transform: scale(1.5);
    }
  }
`;

class StickyHeader extends Component {
  constructor(props) {
    super(props);
    // for the span "J"
    this.firstNameHead = React.createRef();
    // for the span "euel"
    this.firstNameTail = React.createRef();

    // for the span "B"
    this.middleNameHead = React.createRef();
    // for the span "ernard"
    this.middleNameTail = React.createRef();

    // for the span "W"
    this.lastNameHead = React.createRef();
    // for the span "ilkerson"
    this.lastNameTail = React.createRef();

    // for the group of icons
    this.iconsElement = React.createRef();

    this.state = {
      firstNameIsHidden: true,
      middleNameIsHidden: true,
      lastNameIsHidden: true,
      elementIsReady: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    // the first time the viewport becomes ready,
    // wait one second and change state to trigger animation
    setTimeout(() => {
      this.setState({ elementIsReady: true });
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    const { elementIsReady: oldReady } = prevState;
    const { elementIsReady: newReady } = this.state;

    if (oldReady === false && newReady === true) {
      setTimeout(() => {
        this.showOrHideNames();
      }, 1111);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const { elementIsReady } = this.state;
    if (elementIsReady) {
      this.showOrHideNames();
    }
  };

  showOrHideNames = () => {
    const spaceLeftOfIcons =
      this.iconsElement.current.getBoundingClientRect().left - 20;

    const firstNameHeadWidth = this.firstNameHead.current.scrollWidth;
    const firstNameTailWidth = this.firstNameTail.current.scrollWidth;

    const middleNameHeadWidth = this.middleNameHead.current.scrollWidth;
    const middleNameTailWidth = this.middleNameTail.current.scrollWidth;

    const lastNameHeadWidth = this.lastNameHead.current.scrollWidth;
    const lastNameTailWidth = this.lastNameTail.current.scrollWidth;

    const initialsWidth =
      firstNameHeadWidth + middleNameHeadWidth + lastNameHeadWidth;
    const lastNameOnlyWidth = initialsWidth + lastNameTailWidth;
    const firstAndLastOnlyWidth = lastNameOnlyWidth + firstNameTailWidth;
    const allNamesWidth = firstAndLastOnlyWidth + middleNameTailWidth;

    if (inRange(spaceLeftOfIcons, initialsWidth, lastNameOnlyWidth)) {
      this.setState({
        firstNameIsHidden: true,
        middleNameIsHidden: true,
        lastNameIsHidden: true,
      });
    } else if (
      inRange(spaceLeftOfIcons, lastNameOnlyWidth, firstAndLastOnlyWidth)
    ) {
      this.setState({
        firstNameIsHidden: true,
        middleNameIsHidden: true,
        lastNameIsHidden: false,
      });
    } else if (
      inRange(spaceLeftOfIcons, firstAndLastOnlyWidth, allNamesWidth)
    ) {
      this.setState({
        firstNameIsHidden: false,
        middleNameIsHidden: true,
        lastNameIsHidden: false,
      });
    } else if (spaceLeftOfIcons > allNamesWidth) {
      this.setState({
        firstNameIsHidden: false,
        middleNameIsHidden: false,
        lastNameIsHidden: false,
      });
    }
  };

  //
  //
  render() {
    const {
      firstNameIsHidden,
      middleNameIsHidden,
      lastNameIsHidden,
      elementIsReady,
    } = this.state;

    //
    // if elementIsNOTReady, just eager-load the icons
    if (!elementIsReady) {
      return (
        <div style={{ display: "none" }}>
          <img src={githubIcon} alt="github-icon" className="icon-img" />
          <img src={instagramIcon} alt="instagram-icon" className="icon-img" />
          <img src={linkedinIcon} alt="linkedin-icon" className="icon-img" />
          <img src={twitterIcon} alt="twitter-icon" className="icon-img" />
        </div>
      );
    }

    const firstNameStyle = {
      maxWidth: firstNameIsHidden ? "0px" : "220px",
    };
    const firstMiddleStyle = {
      maxWidth: middleNameIsHidden ? "0px" : "220px",
    };
    const firstLastStyle = {
      maxWidth: lastNameIsHidden ? "0px" : "220px",
    };

    return (
      <StyledStickyHeader
        onMouseEnter={this.handleMouseHover}
        onMouseLeave={this.handleMouseHover}
      >
        <div className="full-name">
          <span
            ref={this.firstNameHead}
            className="head-character padding-left-20"
          >
            J
          </span>
          <span
            style={firstNameStyle}
            className="tail-characters"
            ref={this.firstNameTail}
          >
            euel
          </span>
          <span ref={this.middleNameHead} className="head-character">
            B
          </span>
          <span
            style={firstMiddleStyle}
            className="tail-characters"
            ref={this.middleNameTail}
          >
            ernard
          </span>
          <span ref={this.lastNameHead} className="head-character">
            W
          </span>
          <span
            style={firstLastStyle}
            className="tail-characters"
            ref={this.lastNameTail}
          >
            ilkerson
          </span>
        </div>
        <div className="icons" ref={this.iconsElement}>
          <a href="https://github.com/JeuelyFish">
            <img src={githubIcon} alt="icon" className="icon-img" />
          </a>

          <a href="https://www.instagram.com/jeuelbernard">
            <img src={instagramIcon} alt="icon" className="icon-img" />
          </a>

          <a href="https://www.linkedin.com/in/jeuelwilkerson/">
            <img src={linkedinIcon} alt="icon" className="icon-img" />
          </a>

          <a href="https://twitter.com/jeuel">
            <img src={twitterIcon} alt="icon" className="icon-img" />
          </a>
        </div>
      </StyledStickyHeader>
    );
  }
}

export default StickyHeader;

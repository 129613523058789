import React from "react";
import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
  0% { opacity: 0;}
  85% { opacity: 0.85; }
  100% { opacity: 1;}
`;

const slideHorizontallyAndFadeInAnimation = keyframes`
  0% { opacity: 0; transform: translate(-100px, 0px);}
  85% { opacity: 0.85; transform: translate(10px, 0px);}
  100% { opacity: 1; transform: translate(0px, 0px);}
`;

const StyledResume = styled.div`
  background-color: white;
  padding: 1rem 2rem;
  margin: 4rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  width: 35rem;
  animation: fade-in 0.5s;

  h1 {
    opacity: 0;
    animation-name: ${slideHorizontallyAndFadeInAnimation};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
  h2 {
    opacity: 0;
    animation-name: ${slideHorizontallyAndFadeInAnimation};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-delay: 0.25s;
  }
`;

const EmailLink = styled.a`
  text-decoration: none;
  color: black;
`;

const ResumeSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

const SectionLabel = styled.div`
  font-size: 15px;
  font-weight: 700;
  height: 16px;
  width: 10rem;
  border-bottom: 1px solid lightgrey;
  border-image: linear-gradient(
    to right,
    rgba(100, 100, 100, 0.25),
    80%,
    rgba(100, 100, 100, 0.05),
    90%,
    rgba(100, 100, 100, 0)
  );
  border-image-slice: 1;
  margin-bottom: 0.5rem;
  opacity: 0;
  /* animation: slide-horizontally-and-fade-in 0.5s ease-out; */
  animation-name: ${slideHorizontallyAndFadeInAnimation};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
`;

const SectionContent = styled.div`
  font-size: 13px;
  flex: 1;
  min-width: 10rem;
  opacity: 0;
  /* animation: fade-in 0.5s; */
  animation-name: ${fadeInAnimation};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;

  p {
    margin-top: 0;
    line-height: 1.1rem;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      font-weight: 700;
      display: block;
    }
    .title.inline {
      display: inline;
    }

    .sub-title {
      display: block;
      font-style: italic;
    }
  }
`;

const WORKHISTORY = [
  {
    title: "SENIOR ENGINEER",
    subtitle: "HOVER Inc, Remote (Jun 2022 - Present)",
    txt: "Ruby On Rails, GraphQL, TypeScript, React, Jest + Cypress Testing",
  },
  {
    title: "SENIOR FRONTEND ENGINEER",
    subtitle: "Mode Analytics, San Francisco, CA (Jan 2019 - Apr 2022)",
    txt: "Angular 10, Angular.js, TypeScript, React, Gatsby.js",
  },
  {
    title: "FRONTEND ENGINEER",
    subtitle: "Lytmus, San Francisco, CA (May 2018 - Oct 2018)",
    txt: "Angular.js, Python (Pylons Framework), Selenium Testing",
  },
  {
    title: "FULLSTACK ENGINEER",
    subtitle: "First Republic Bank, San Francisco, CA (May 2017 - Apr 2018)",
    txt: "React.js and React-Native.js (w/ Redux), Elm, Elixir (Phoenix Framework)",
  },
  {
    title: "JAVASCRIPT WEB DEVELOPER",
    subtitle: "Quantifind, Menlo Park, CA (Aug 2015 - Apr 2017)",
    txt: "Polymer.js, Express.js, Mocha Web-Component Testing",
  },
];

export default function Resume() {
  const languagesText = " Javascript, JSX, Elm, HTML5, CSS/SASS";
  const frameworksText = " Angular, Express.js, Vue.js, React.js, Polymer.js";
  const historySections = WORKHISTORY.map((wrkObj) => (
    <p key={wrkObj.subtitle}>
      <span className="title">{wrkObj.title}</span>
      <span className="sub-title">{wrkObj.subtitle}</span>
      {wrkObj.txt}
    </p>
  ));

  return (
    <StyledResume>
      <h1>JEUEL BERNARD WILKERSON</h1>
      <h2>Senior Frontend Software Engineer</h2>
      <EmailLink href="mailto:jeuelwilkerson@gmail.com">
        jeuelwilkerson@gmail.com
      </EmailLink>

      <hr />

      <ResumeSection>
        <SectionLabel>TECHNOLOGIES</SectionLabel>
        <SectionContent>
          <p>
            <span className="title inline">LANGUAGES</span>
            {languagesText}
          </p>
          <p>
            <span className="title inline">FRAMEWORKS</span>
            {frameworksText}
          </p>
        </SectionContent>
      </ResumeSection>

      <hr />

      <ResumeSection>
        <SectionLabel>EXPERIENCE</SectionLabel>
        <SectionContent>{historySections}</SectionContent>
      </ResumeSection>

      <hr />

      <ResumeSection>
        <SectionLabel>EDUCATION</SectionLabel>
        <SectionContent>
          <p>
            <span className="title">MASTER OF ARTS, PHILOSOPHY</span>
            <span className="sub-title">
              San Francisco State University (December 2012)
            </span>
          </p>
          <p>
            <span className="title">BACHELOR OF ARTS, PHILOSOPHY</span>
            <span className="sub-title">
              University of California, Davis (June 2010)
            </span>
          </p>
        </SectionContent>
      </ResumeSection>

      <hr />

      <ResumeSection>
        <SectionLabel>OTHER ACTIVITIES</SectionLabel>
        <SectionContent>
          <p>
            <span className="title">HACKBRIGHT ACADEMY MENTOR</span>
            Mentor students at Hackbright Academy, an all Women’s coding
            Bootcamp, by meeting bi-weekly to talk about and review code.
          </p>
        </SectionContent>
      </ResumeSection>
    </StyledResume>
  );
}
